.language-switcher {
  position: relative;
  display: inline-block;
}

.language-button {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease;
}

.language-button:hover {
  background-color: #f0f0f0;
}

.globe-icon {
  margin-right: 8px;
}

.dropdown-icon {
  margin-left: 8px;
  font-size: 12px;
}

.dropdown-menu {
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.flag {
  margin-right: 10px;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
