/* Container for the Impressum page */
.impressum-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  background-color: #f9fafc;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}

/* Card to display Impressum content */
.impressum-card {
  background-color: #ffffff;
  padding: 40px 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  text-align: center; /* Centered text */
}

/* Logo styling */
.impressum-logo {
  width: 150px; /* Increased logo size */
  margin-bottom: 20px; /* Space below the logo */
}

/* Back button styling */
.back-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem; /* Smaller font size */
  margin-top: 20px; /* Space above the button */
}

.back-button:hover {
  background-color: #0056b3;
}

/* Title styling */
.impressum-title {
  font-size: 2.4rem; /* Larger title size */
  font-weight: 600;
  color: #333;
  margin: 10px 0; /* Reduced margin */
}

/* Content section styling */
.impressum-content {
  line-height: 1.6; /* Improved line spacing */
  font-size: 1rem; /* Consistent font size */
  color: #555;
  text-align: left; /* Left align for better readability */
}

/* Section title styling */
.impressum-section {
  font-size: 1.1rem; /* Smaller section titles */
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

/* Each detail (company name, address, etc.) */
.impressum-detail {
  margin-bottom: 10px; /* Reduced bottom margin */
  color: #666;
}

/* Styling for clickable email link */
.impressum-link {
  color: inherit; /* Inherits color from parent */
  text-decoration: none; /* No underline */
}

.impressum-link:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .impressum-card {
    padding: 30px 20px;
  }

  .impressum-title {
    font-size: 2rem;
  }

  .impressum-content {
    font-size: 1rem;
  }

  .impressum-section {
    font-size: 1.1rem;
  }

  .impressum-detail {
    margin-bottom: 8px; /* Further reduced margin */
  }
}
