.purchase-credits {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.purchase-credits h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.credits-selector {
  width: 100%;
  margin-bottom: 20px;
}

.credits-selector label {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  color: #555;
  font-family: 'Open Sans', sans-serif;
}

.credits-selector select {
  padding: 10px;
  font-size: 1em;
  border: 2px solid #dcdcdc;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.stripe-buy-button-container {
  margin-top: 20px;
}

.stripe-buy-button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.stripe-buy-button:hover {
  background-color: #2980b9;
}

.stripe-buy-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.error {
  color: red;
  margin-top: 20px;
}
