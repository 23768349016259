/* PrivacyPolicy.css */
.privacy-policy {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.privacy-policy p {
  margin-bottom: 1rem;
  line-height: 1.6;
}
