/* src/pages/AdCreation/ImageUpload.css */

.image-upload-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px auto;
  padding: 40px;
  border: 2px dashed #3498db;
  border-radius: 15px;
  background-color: #fafafa;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  max-width: 800px;
  font-family: 'Roboto', sans-serif;
}

.image-upload-container.drag-over {
  border-color: #2980b9;
  background-color: #f0f8ff;
}

.image-upload-container:hover {
  border-color: #2980b9;
  background-color: #f9f9f9;
}

.image-upload-container input[type='file'] {
  display: none;
}

.drag-drop-text {
  color: #333;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.upload-info {
  margin-top: 15px;
  font-size: 16px;
  color: #666;
}

.upload-info ul {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
}

.upload-info li {
  margin: 5px 0;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}

.image-preview {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.image-preview button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
  transition: background-color 0.3s ease;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.action-buttons {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.optimize-button,
.download-button {
  padding: 12px 25px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.download-button {
  background-color: #e67e22;
}

.optimize-button:hover {
  background-color: #2980b9;
}

.download-button:hover {
  background-color: #d35400;
}
