.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Slightly more transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh; /* Ensure modal doesn't overflow */
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Allow vertical scrolling */
}

.modal-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  color: #34495e;
  text-align: center;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  position: relative;
}

.image-navigation-button {
  padding: 12px;
  background-color: #3498db;
  border: none;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.image-navigation-button:hover {
  background-color: #2980b9;
  transform: scale(1.1);
}

.image-navigation-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.image-navigation-button:nth-child(1) {
  left: 0;
}

.image-navigation-button:nth-child(3) {
  right: 0;
}

.image-canvas {
  max-width: 100%;
  max-height: 270px;
  border: 2px solid #ddd;
  border-radius: 10px;
  object-fit: cover;
}

.scrollable-content {
  width: 100%;
}

.controls {
  width: 100%;
  margin-bottom: 20px;
}

.slider-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-group label {
  margin-bottom: 10px;
  font-size: 15px;
  color: #34495e;
}

.slider-group input[type="range"] {
  width: 100%;
  appearance: none;
  height: 8px;
  background: #ecf0f1;
  border-radius: 5px;
  outline: none;
}

.slider-group input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 14px;
  height: 14px;
  background-color: #3498db;
  border-radius: 50%;
  cursor: pointer;
}

.slider-group input[type="range"]::-moz-range-thumb {
  width: 14px;
  height: 14px;
  background-color: #3498db;
  border-radius: 50%;
  cursor: pointer;
}

.auto-optimize-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.auto-optimize-button,
.reset-button {
  padding: 12px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.auto-optimize-button {
  background-color: #2ecc71;
  color: white;
}

.auto-optimize-button:hover {
  background-color: #27ae60;
  transform: translateY(-2px);
}

.reset-button {
  background-color: #e74c3c;
  color: white;
}

.reset-button:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

.modal-actions {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px; /* Add space between the buttons */
}

.save-button,
.cancel-button {
  padding: 12px 30px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.save-button {
  background-color: #3498db;
  color: white;
}

.save-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.cancel-button {
  background-color: #7f8c8d;
  color: white;
}

.cancel-button:hover {
  background-color: #636e72;
  transform: translateY(-2px);
}

.advanced-settings-toggle {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 12px;
  font-size: 15px;
  margin-bottom: 15px;
  transition: background-color 0.3s;
}

.advanced-settings-toggle:hover {
  background-color: #2980b9;
}

.advanced-controls {
  display: flex;
  flex-direction: column;
  width: 100%;
}
