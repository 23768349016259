/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #ffffff;
  color: #333;
  border-bottom: 2px solid #e1e1e1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.logo-section {
  display: flex;
  align-items: center;
}

.title {
  font-size: 1.5em;
  font-weight: 500;
  color: #2c3e50;
  font-family: 'Roboto', sans-serif;
  margin-right: 20px;
}

.right-section {
  display: flex;
  align-items: center;
}

.right-section .logo {
  height: 30px;
  padding-left: 10px;
}

.header-controls-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  padding: 15px 40px;
  border-bottom: 1px solid #e1e1e1;
}

.header-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  align-items: center;
}

.control-group {
  display: flex;
  align-items: center;
  gap: 15px;
}

.credits-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  background-color: #f5f5f5;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  height: 40px;
}

.language-switcher {
  display: flex;
  align-items: center;
}

.language-switcher select {
  background-color: #f5f5f5;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  height: 40px;
  box-sizing: border-box;
}

/* Logout Button Styles */
.control-group button.logout-button {
  background-color: #f5f5f5; /* Matching the background with credits and language switcher */
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  color: #333; /* Dark gray text for consistency */
  font-size: 14px;
  cursor: pointer;
  padding: 5px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 80px; /* Compact width */
  box-sizing: border-box;
  margin: 0;
}

.control-group button.logout-button:hover {
  background-color: #e0e0e0; /* Subtle hover effect */
  border-color: #c0c0c0;
}

/* Buy Credits Button */
.control-group .buy-credits-button {
  background-color: #3498db;
  border: 1px solid #2980b9;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  padding: 5px 15px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 120px;
  box-sizing: border-box;
}

.control-group .buy-credits-button:hover {
  background-color: #2980b9;
  border-color: #1c6ea4;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .logo-section, .right-section {
    justify-content: center;
    margin: 10px 0;
  }

  .header-controls-container {
    flex-direction: column;
  }

  .header-controls {
    flex-direction: column;
    align-items: center;
  }

  .control-group {
    flex-direction: column;
    align-items: center;
  }

  .control-group > * {
    margin-bottom: 10px;
  }

  .control-group > *:last-child {
    margin-bottom: 0;
  }
}

/* General App Styles */
.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Image Upload Styles */
.image-upload-container {
  border: 2px solid #e1e1e1;
  background-color: #fff;
  color: #666;
  padding: 40px;
  border-radius: 8px;
}

.image-upload-container.drag-over {
  border-color: #3498db;
  background-color: #e6f7ff;
}

.image-upload-container:hover {
  border-color: #3498db;
}

.image-upload-container input[type="file"] {
  display: none;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.image-preview {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-preview button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ffffff;
  color: #666;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
  transition: background-color 0.3s ease;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview button:hover {
  background-color: #f0f0f0;
  border-color: #bbb;
}

.image-preview button::before {
  content: '';
}

/* Footer Styles */
footer {
  background-color: #f8f8f8;
  color: #333;
  border-top: 1px solid #e1e1e1;
  padding: 20px 0;
  box-shadow: none;
}

footer .footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

footer .footer-info {
  font-size: 14px;
}

footer .footer-logo {
  height: 40px;
  margin-bottom: 10px;
}

/* View History Button */
.control-group .view-history-button {
  background-color: #f5f5f5; /* Match the background with credits and language switcher */
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  color: #333; /* Dark gray text for consistency */
  font-size: 14px;
  cursor: pointer;
  padding: 5px 15px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 120px; /* Compact width */
  box-sizing: border-box;
  margin: 0;
}

.control-group .view-history-button:hover {
  background-color: #e0e0e0; /* Subtle hover effect */
  border-color: #c0c0c0;
}

.control-group .view-history-button svg {
  margin-right: 5px; /* Space between icon and text */
}
