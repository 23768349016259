/* Base settings */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    margin: 0;
    padding: 0;
}

/* Form Container */
.adForm {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 20px auto;
    position: relative;
}

/* Section Headings */
.adForm h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: left;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    font-size: 1.5em;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Form Sections */
.formSection {
    margin-bottom: 30px;
}

.formGroup {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: flex-start;
    position: relative;
}

.formGroup label {
    width: 30%;
    min-width: 150px;
    text-align: right;
    padding-right: 15px;
    font-weight: 500;
    color: #666;
}

.formGroup input,
.formGroup select,
.formGroup textarea,
.formGroup .datePickerContainer {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f9f9f9;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.formGroup input:focus,
.formGroup select:focus,
.formGroup textarea:focus {
    border-color: #3498db;
    background-color: #fff;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
}

.requiredLabel {
    position: relative;
}

.requiredLabel::after {
    content: ' *';
    color: red;
    margin-left: 5px;
}

/* Date Picker Specifics */
.datePickerContainer {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
    border: none;
}

/* Suggestions Styles */
.suggestions {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 5px;
    list-style-type: none;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 30%;
    width: calc(70% - 20px);
    z-index: 1000;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.suggestions li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
}

.suggestions li:hover {
    background-color: #f0f0f0;
}

.suggestionIcon {
    margin-right: 10px;
    color: #3498db;
}

/* Buttons */
.button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    display: block;
    margin: 20px auto 0 auto;
}

.button:hover {
    background-color: #2980b9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.button:active {
    transform: translateY(2px);
}

/* Small button for adding appointments */
.smallButton {
    background-color: #3498db;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-left: 10px;
}

.smallButton:hover {
    background-color: #2980b9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.smallButton:active {
    transform: translateY(2px);
}

/* Modern Copy Button */
.copyButton {
    margin: 20px auto;  /* Center the button */
    background-color: #3498db;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    display: block;      /* Make the button a block element */
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.1s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.copyButton:hover {
    background-color: #2980b9;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.copyButton:active {
    transform: translateY(2px);
}

.copyButton svg {
    font-size: 20px;
}

.copied {
    background-color: #27ae60;
}

/* Loading Container */
.loadingContainer {
    text-align: center;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #dfe3e8;
    margin-top: 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    max-width: 800px;
    margin: 20px auto;
    position: relative;
}

/* Loading Stepper */
.stepperContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.stepIcon {
    color: #3498db;
}

.loadingMessage {
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    max-width: 500px;
    width: 80%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border: 1px solid #ccc;
    z-index: 1001;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.modalHeader h2 {
    font-size: 1.5em;
    color: #333;
    margin: 0;
}

.modalContent {
    padding-top: 20px;
    font-size: 1.1em;
    color: #555;
}

/* Improved Info Button */
.infoButton {
    background-color: transparent; /* Transparent background */
    border: none;                  /* Remove button border */
    color: #3498db;                /* Blue color for the info icon */
    font-size: 20px;               /* Larger icon size */
    padding: 2px;                  /* Minimal padding around the icon */
    width: auto;                   /* Automatically adjust width */
    height: auto;                  /* Automatically adjust height */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;               /* Show pointer on hover */
    transition: color 0.3s ease;
}

.infoButton:hover {
    color: #2980b9;                /* Darker blue on hover */
}

.infoButton:active {
    transform: translateY(1px);    /* Slight click effect */
}

.infoButton svg {
    font-size: 20px;               /* Adjust icon size appropriately */
}


/* Close Button in Modals */
.closeButton {
    background: none;
    border: none;
    color: #aaa;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.closeButton:hover {
    color: #555;
}

/* Modal Styling */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    max-width: 600px;
    width: 90%;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    z-index: 1001;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.modalContent p {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
    line-height: 1.5;
}


/* Checkbox Group */
.checkboxGroup {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: calc(30% + 15px);
}

.checkboxGroup input[type="checkbox"] {
    margin-right: 10px;
    accent-color: #3498db;
}

/* Appointment List */
.appointmentList {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

.appointmentList li {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
}

.appointmentList li:hover {
    background-color: #f4f4f4;
}

.appointmentList li button {
    background: none;
    border: none;
    color: #e74c3c;
    font-size: 1.2em;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.2s ease;
}

.appointmentList li button:hover {
    color: #c0392b;
    transform: scale(1.1);
}

.amenitiesSection {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    max-width: 100%;
}

.amenitiesSection h3 {
    font-size: 1.6em;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
}

.amenitiesList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.amenitiesList li {
    width: 45%;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 1em;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

.amenitiesList li:hover {
    transform: translateY(-5px);
}

.amenityIcon {
    margin-right: 10px;
    color: #3498db;
    font-size: 1.5em;
}

.amenityName {
    flex: 1;
    font-weight: 500;
    /* Ensure there's space after the amenity name */
    margin-right: 10px;
}

.amenityDistance {
    font-style: italic;            /* Makes the distance text italic */
    color: #555;                   /* Sets a subtle color for the distance text */
    font-size: 0.9em;              /* Slightly reduces the font size for distinction */
    margin-left: auto;             /* Pushes the distance to the far right */
    white-space: nowrap;           /* Prevents distance text from wrapping */
}

/* Responsive Adjustments (if needed) */
@media (max-width: 768px) {
    .amenitiesList li {
        width: 100%;
    }

    .amenityDistance {
        margin-left: 10px;         /* Adjust margin for smaller screens */
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .adForm {
        padding: 20px;
        margin: 10px auto;
    }

    .adForm h2 {
        font-size: 1.2em;
    }

    .formGroup,
    .checkboxGroup {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
    }

    .formGroup label,
    .checkboxGroup label {
        width: 100%;
        text-align: left;
        padding-right: 0;
        margin-bottom: 5px;
    }

    .formGroup input,
    .formGroup select,
    .formGroup textarea {
        width: 100%;
    }

    .suggestions {
        left: 0;
        width: 100%;
    }

    .button {
        width: 100%;
        padding: 12px 0;
    }

    .loadingContainer {
        padding: 20px;
    }

    .progressBar {
        height: 12px;
    }

    .loadingMessage {
        font-size: 16px;
    }

    /* Amenities Section */
    .amenitiesList {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 10px;
    }

    .amenitiesList li {
        width: 100%;
        justify-content: flex-start;
    }

    .amenityIcon {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .adForm {
        padding: 15px;
    }

    .adForm h2 {
        font-size: 1.1em;
    }

    .formGroup label {
        font-size: 0.9em;
    }

    .formGroup input,
    .formGroup select,
    .formGroup textarea {
        padding: 8px;
        font-size: 14px;
    }

    .button {
        font-size: 14px;
        padding: 10px 0;
    }

    .smallButton {
        font-size: 12px;
    }

    .loadingMessage {
        font-size: 14px;
    }

    .amenitiesList li {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .adForm {
        padding: 10px;
    }

    .adForm h2 {
        font-size: 1em;
    }

    .formGroup label {
        font-size: 0.8em;
    }

    .formGroup input,
    .formGroup select,
    .formGroup textarea {
        font-size: 13px;
    }


    .loadingMessage {
        font-size: 13px;
    }
}

/* Screenshot Info Label */
.screenshotInfoLabel {
    text-align: center;
    font-size: 14px;
    color: #666;
    margin-top: 10px;
    margin-bottom: 20px;
}

/* Generated Ad Container */
.generatedAd {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    margin-top: 20px;
    border: 1px solid #e1e1e1;
    font-family: 'Roboto', sans-serif;
    color: #333;
    text-align: left;
    font-weight: normal;
    max-width: 800px;
    margin: 20px auto;
    line-height: 1.6;
}

.generatedAd h2 {
    font-size: 1.8em;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin: 0 0 20px 0;
}

.generatedAd p {
    line-height: 1.6;
    margin: 10px 0;
}

.generatedAd strong {
    font-weight: bold;
}

.addressInput::placeholder {
  color: #999; /* Default placeholder color */
}

.errorInput::placeholder {
  color: red; /* Red color for error placeholder */
}

.errorInput {
  border: 2px solid red; /* Highlight the input field in red */
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5); /* Optional: Add shadow for emphasis */
}