/* Global Box-Sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* General Styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
  color: #333;
  overflow-x: hidden;
}

.landingPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header Styles */
.header {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  position: sticky;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 50px;
}

.navMenu {
  display: none;
}

.navMenu.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.navButton {
  padding: 1rem;
  background: none;
  border: none;
  color: #0044cc;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.navButton:hover {
  background-color: #f0f0f0;
}

.hamburgerMenu {
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
}

.hamburgerIcon {
  width: 25px;
  height: 3px;
  background-color: #333;
  display: block;
  position: relative;
}

.hamburgerIcon::before,
.hamburgerIcon::after {
  content: '';
  width: 25px;
  height: 3px;
  background-color: #333;
  position: absolute;
  left: 0;
}

.hamburgerIcon::before {
  top: -8px;
}

.hamburgerIcon::after {
  top: 8px;
}

.headerActions {
  margin-left: 1rem;
}

/* Hero Section */
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 1.5rem 4rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay to enhance text readability */
  z-index: 1;
}

.heroContent {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 2rem;
}

.heroTitle {
  font-size: 3rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  font-weight: 700;
}

.heroSubtitle {
  font-size: 1.5rem;
  margin-bottom: 3rem;
  color: #e0e0e0;
  line-height: 1.5;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

/* Base Button Styles */
.baseButton {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #0044cc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: block;
  text-align: center;
  margin-top: 1rem;
  transition: background-color 0.3s, transform 0.2s;
  width: auto;
  max-width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.baseButton:hover {
  background-color: #003399;
  transform: translateY(-2px);
}

.ctaButton {
  background: linear-gradient(135deg, #ff9900, #ffb84d);
  border-radius: 30px;
  width: 100%;
  max-width: 300px;
  font-size: 1.2rem;
  font-weight: bold;
}

.ctaButton:hover {
  background: linear-gradient(135deg, #e68a00, #ffaa33);
}

.loginButton {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: 2px solid #fff;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 100%;
  max-width: 250px;
  font-size: 1rem;
}

.loginButton:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

/* Full Width Modifier */
.fullWidth {
  width: 100% !important;
  max-width: 100% !important;
}

/* Features Section */
.features {
  padding: 4rem 1.5rem;
  background-color: #f8f9fa;
  text-align: center;
}

.features h2 {
  font-size: 2rem;
  margin-bottom: 3rem;
}

.featureList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.feature {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.featureIcon {
  font-size: 2.5rem;
  color: #0044cc;
  margin-bottom: 1rem;
}

.feature h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.feature p {
  font-size: 1rem;
  color: #666;
}

/* Pricing Section */
.pricing {
  padding: 4rem 1.5rem;
  background-color: #fff;
  text-align: center;
}

.pricing h2 {
  font-size: 2rem;
  margin-bottom: 3rem;
}

.pricingPlans {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}


.plan {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 10px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px; /* Adjust based on your preference */
}


.plan h3 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.plan p {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

/* Specific Button Variants */
.buyButton {
  background-color: #0044cc;
  border-radius: 5px;
}

.buyButton:hover {
  background-color: #003399;
}

.contactButton {
  background-color: #0044cc;
  border-radius: 5px;
}

.contactButton:hover {
  background-color: #003399;
}

.redeemButton {
  background-color: #0044cc;
  border-radius: 5px;
}

.redeemButton:hover {
  background-color: #003399;
}

/* Pricing Button Modifier */
.pricingButton {
  width: 100%;
  max-width: 100%;
}
/* Footer */
.footer {
  background-color: #333;
  color: #fff;
  padding: 2rem 1.5rem;
}

.footerContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;
  justify-content: space-between;
  align-items: flex-start;
}

.footerColumn {
  flex: 1;
  min-width: 180px;
  text-align: left;
  padding: 0 1rem;
}

.footerColumn h4 {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.footerColumn p,
.footerColumn a {
  display: block;
  font-size: 1rem;
  color: #ccc;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footerColumn a:hover {
  color: #fff;
}

.emailContainer {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.icon {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.emailLink {
  margin: 0;
  padding-top: 5px;
  color: #fff;
}

.emailLink:hover {
  color: #e0e0e0;
}

.socialIcon {
  font-size: 2rem;
  color: #fff;
  margin-right: 1rem;
  transition: color 0.3s;
}

.socialIcon:hover {
  color: #0077b5;
}

.footerBottom {
  text-align: center;
  margin-top: 1.5rem;
  border-top: 1px solid #444;
  padding-top: 1rem;
}

.footerBottom p {
  font-size: 0.9rem;
  color: #ccc;
}

/* Centered Redeem Button */
.redeemButtonContainer {
  display: flex;
  justify-content: center;
  margin: 2rem 0; /* Adds space above and below */
}

.redeemButtonFooter {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #0044cc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: fit-content;
}

.redeemButtonFooter:hover {
  background-color: #003399;
}

/* Footer Payment Icons */
.paymentIconsColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.paymentIcons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
}

.paymentIcon {
  width: 40px;
  height: auto;
  filter: grayscale(100%);
  transition: filter 0.3s, transform 0.3s;
}

.paymentIcon:hover {
  filter: grayscale(0%);
  transform: scale(1.05);
}

/* Hide payment icons on mobile screens */
@media (max-width: 768px) {
  .paymentIconsColumn {
    display: none;
  }
}

/* Responsive Layout for Footer */
@media (min-width: 768px) {
  .footerContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footerColumn {
    text-align: left;
    padding: 0 2rem;
    justify-content: center;
  }
}


/* Responsive Styles */
@media (min-width: 768px) {
  .navMenu {
    display: flex !important;
    position: static;
    flex-direction: row;
    margin-left: auto;
  }

  .navButton {
    padding: 0 1rem;
    text-align: center;
    font-size: 1rem;
  }

  .hamburgerMenu {
    display: none;
  }

  .headerActions {
    margin-left: 1rem;
  }

  .hero {
    text-align: center;
    padding: 8rem 1.5rem 6rem;
  }

  .buttonContainer {
    flex-direction: row;
    justify-content: center;
  }

  .features h2,
  .pricing h2 {
    font-size: 2.5rem;
  }

  .featureList {
    grid-template-columns: repeat(3, 1fr);
  }

  .pricingPlans {
    grid-template-columns: repeat(3, 1fr);
  }

  .footerContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footerColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the top */
  }

  .footerBottom {
    margin-top: 3rem;
  }

  .heroTitle {
    font-size: 4rem;
  }

  .heroSubtitle {
    font-size: 2rem;
  }
}

/* Responsive Adjustments for Pricing Section */
@media (max-width: 911px) and (min-width: 768px) {
  .pricingPlans {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
}

/* Enhanced Button Styles for Modern Look */
.baseButton {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ctaButton {
  background: linear-gradient(135deg, #ff9900, #ffb84d);
}

.ctaButton:hover {
  background: linear-gradient(135deg, #e68a00, #ffaa33);
}

.loginButton {
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid #fff;
  color: #fff;
}

.loginButton:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Adjusting Hero Section for Better Aesthetics */
.hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.heroContent {
  background: none;
  padding: 3rem 2rem;
}

.heroTitle {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.heroSubtitle {
  font-size: 1.5rem;
  margin-bottom: 3rem;
  color: #e0e0e0;
  line-height: 1.5;
}

/* Responsive Typography */
@media (min-width: 768px) {
  .heroTitle {
    font-size: 4rem;
  }

  .heroSubtitle {
    font-size: 2rem;
  }
}

/* Additional Header Enhancements */
.header a {
  text-decoration: none;
  color: inherit;
}

.navButton {
  position: relative;
}

.navButton::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #0044cc;
  transition: width 0.3s ease, left 0.3s ease;
}

.navButton:hover::after {
  width: 100%;
  left: 0;
}

.headerActions .languageSwitcher {
  display: flex;
  align-items: center;
}

.languageSwitcher select {
  background: none;
  border: none;
  color: #0044cc;
  font-weight: bold;
  cursor: pointer;
}

/* Mobile Adjustments */
@media (max-width: 767px) {
  .heroTitle {
    font-size: 2.5rem;
  }

  .heroSubtitle {
    font-size: 1.2rem;
  }

  .features h2,
  .pricing h2 {
    font-size: 1.8rem;
  }

  .featureIcon {
    font-size: 2rem;
  }

  .feature h3 {
    font-size: 1.3rem;
  }

  .feature p {
    font-size: 0.9rem;
  }

  .pricingPlans {
    grid-template-columns: 1fr;
  }
}

/* Adjusting the Hero Image Display */
@media (max-width: 767px) {
  .hero {
    background-attachment: fixed;
  }
}
