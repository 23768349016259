/* Footer.css */
.footer {
    background-color: #f8f8f8;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.footer-logo {
    height: 50px;
    margin-bottom: 10px;
}

.footer-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 14px;
    line-height: 1.6;
    color: #666;
}

.footer-info a {
    color: #333;
    text-decoration: none;
    margin: 0 10px;
}

.footer-info a:hover {
    text-decoration: underline;
}

.linkedin-icon {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.linkedin-svg {
    fill: #666;
    transition: fill 0.3s;
}

.linkedin-svg:hover {
    fill: #0077b5;
}

/* Modal styles */
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    max-width: 500px;
    width: 80%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border: 1px solid #ccc;
    z-index: 1001;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.Modal h2 {
    margin-top: 0;
}

.Modal p {
    white-space: pre-wrap;
}
