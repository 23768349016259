/* General layout for full-page layout - specific to ad-history-page */
.ad-history-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the page takes the full height of the viewport */
}

.ad-history-page .main-content {
  flex-grow: 1; /* This ensures that the content takes up space and pushes the footer down if the content is short */
  padding-bottom: 20px; /* Avoid overlap with footer when content grows */
}

/* Footer styles - specific to ad-history-page */
.ad-history-page footer {
  background-color: #fff;
  padding: 15px 20px;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  color: #666;
  margin-top: auto; /* This ensures the footer sticks to the bottom if the content is short */
}

/* Filter Menu */
.filter-container {
  margin: 15px 0;
  padding: 0 20px;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.filter-options label {
  font-size: 1rem;
  color: #333;
  cursor: pointer;
}

.filter-options input {
  margin-right: 8px;
}

.date-range-selects {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 10px;
}

.date-range-selects label {
  font-size: 0.9rem;
  color: #555;
}

.date-range-selects input {
  padding: 5px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Ad History Cards */
.ad-history-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px 20px;
}

.ad-history-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

.ad-history-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.ad-history-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
}

.ad-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ad-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.ad-title {
  font-size: 1.2rem;
  color: #333;
  margin: 0;
  text-decoration: none;
  font-weight: bold;
}

.external-link-icon {
  font-size: 1.2rem;
  color: #888;
}

.ad-preview {
  padding: 10px 15px;
  flex-grow: 1;
  color: #555;
  font-size: 0.95rem;
}

.ad-actions {
  padding: 10px 15px;
  display: flex;
  justify-content: flex-end;
}

.copy-button {
  background-color: transparent;
  border: none;
  color: #888;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.copy-button:hover {
  color: #333;
}

.ad-date {
  font-size: 0.85rem;
  color: #888;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copy-button {
  margin-left: 10px;
}

/* Back to Home Button */
.back-to-home-button {
  background-color: #fff;
  color: #333;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.back-to-home-button:hover {
  background-color: #f0f0f0;
  border-color: #bbb;
}

/* Modal styles */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.modal-content {
  background-color: #fff;
  padding: 25px;
  border-radius: 12px;
  max-width: 600px;
  width: 100%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 24px;
  cursor: pointer;
  color: #888;
}

.close-button:hover {
  color: #333;
}

.modal-copy-button {
  display: block;
  margin: 15px auto;
}

.title {
  font-size: 2rem;
  color: #333;
  text-decoration: none;
  margin: 0;
}

.logo-link {
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ad-history-list {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
