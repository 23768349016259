.generated-ad {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    margin-top: 20px;
    border: 1px solid #e1e1e1;
    font-family: 'Roboto', sans-serif;
    color: #333;
    text-align: left;
    font-weight: normal;
}

.copy-button {
    margin-top: 10px;
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.copy-button:hover {
    background-color: #2980b9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.generated-ad h1 {
    font-size: 2em;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin: 0 0 20px 0;
}

.generated-ad p {
    line-height: 1.6;
    margin: 10px 0;
}

.generated-ad strong {
    font-weight: bold;
}
