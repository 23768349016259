.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  padding: 0 20px;
}

.loginCard {
  background-color: #ffffff;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.logo {
  max-width: 150px;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.loginButton {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  color: #ffffff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
}

.loginButton:hover {
  background-color: #0056b3;
}

.signupPrompt {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}

.signupLink {
  color: #007bff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.signupLink:hover {
  text-decoration: underline;
}

.loginLanguageSwitcher {
  margin-top: 20px;
  display: flex;
  justify-content: center; /* This ensures the content inside is centered */
}

