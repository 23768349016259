.calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2.5rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 2.5rem auto;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  transition: box-shadow 0.3s ease;
}

.calculator:hover {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
}

.calculator h3 {
  font-size: 1.75rem;
  color: #2c3e50;
  margin-bottom: 3rem;
  text-align: center;
  font-weight: 600;
}

.sliderContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2.5rem;  /* Increased margin between sliders */
}

.sliderLabel {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #34495e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderTrack {
  height: 6px;
  width: 100%;
  background-color: #edf2f7;  /* Slightly lighter track color */
  border-radius: 3px;
}

.sliderThumb {
  height: 18px;
  width: 18px;
  background-color: #3498db;
  border-radius: 50%;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.sliderValue {
  font-size: 0.95rem;
  font-weight: 500;
  color: #2c3e50;
  margin-top: 0.3rem;
  text-align: center;
}

.savingsResult {
  font-size: 1.4rem;  /* Slightly smaller font */
  font-weight: 500;
  color: #2c3e50;
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  margin-top: 2.5rem;
  transition: background-color 0.3s ease;
}

.savingsResult:hover {
  background-color: #ebebeb;
}
